import React from 'react'
import {
  LegacyCard,
  TextField,
  TextContainer,
  Text
} from "@shopify/polaris"
import styled from 'styled-components'
import update from 'immutability-helper'
import ManualRules from './manualRules'
import OfferTypeCheckbox from "../common/offerTypeCheckbox/offerTypeCheckbox";
import CardDivider from '../../../../components/cardDivider'
import CardWrapper from '../../../../components/cardWrapper'
const CheckoutOfferTypeWrapper = styled.div`
    margin-bottom: 20px;
    .rule-type {
        padding-top: 1.6rem;
        .rule-type-subtitle {
            margin: 0px 0 20px;
        }
        .rule-type-option {
            border: 2px solid #AAA;
            border-radius: 4px;
            padding: 10px 10px;
            margin-bottom: 25px;
            .rule-type-image {
                text-align: center;
                img {
                    width: 85px;
                }
            }
            .rule-type-image:nth-of-type(2) {
                img {
                    width: 96px;
                }
            }
            .rule-type-desp {
                max-width: 210px;
            }
        }
        .active {
            border: 2px solid #008060;
        }
    }
`
function ProductOfferType(props) {
    
    const { host, productOfferType, handleProductOfferTypeChange, shopBrainType, shop, addSmartRule, state, setState, productPickerShow, setProductPickerShow, error, setError, productEdit, setProductEdit, currency, currencySymbol, locale, versionAnalytics, versionData, setVersionData, version, setVersion, token, setCheckoutOfferType, existingOffer, customizationEdit, setCustomizationEdit } = props
    
    return (
        <CheckoutOfferTypeWrapper>
        <CardWrapper title="Name your Upsell" subtitle="Name your Upsell, Product Selection">
            <div style={{marginTop: "20px"}}>
                <div style={{marginTop: "20px"}}>
                    <Text variant="headingSm" as="h3">Name your offer</Text>
                    <TextField
                        label="Internal offer nickname" 
                        placeholder="e.g. Holiday Promo 2023" 
                        labelHidden={true}
                        onChange={(val) => {
                            setState(update(state, { name: { $set: val }}))
                        }}
                        value={state.name}
                    />
                    {state.id ?
                        <TextContainer spacing="tight">
                        <Text variant="bodyMd" as="span" tone="subdued">Offer ID:{state.id}</Text>
                        </TextContainer>
                        :
                        false
                    }      
                    <OfferTypeCheckbox
                    shopBrainType={shopBrainType}
                    offerType={productOfferType}
                    changeOfferType={handleProductOfferTypeChange}
                    />
                </div>
            </div>
        </CardWrapper>
        <ManualRules host={host} shop={shop} token={token} addSmartRule={addSmartRule} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} error={error} setError={setError} productEdit={productEdit} setProductEdit={setProductEdit} currency={currency} currencySymbol={currencySymbol} locale={locale} versionAnalytics={versionAnalytics} versionData={versionData} setVersionData={setVersionData} version={version} setVersion={setVersion} productOfferType={productOfferType} existingOffer={existingOffer} customizationEdit={customizationEdit} setCustomizationEdit={setCustomizationEdit}/>
        </CheckoutOfferTypeWrapper>
    )
}

export default ProductOfferType;